import React from "react"
import {RadioButtons} from '@common'


const methods = [
    {name: 'Przelew'},
    {name: 'Płatne przy odbiorze'},
    {name: 'Blik'}
]

const PaymentMethod = ({ customer, setPayment }) => {
    const { payment } = customer
    return (
        <div className="delivery">
            <h5>Sposób płatności</h5>
            <RadioButtons items={methods}
                itemMapper={(el) => ({
                    name:"payment",
                    onChange:() => {
                        setPayment(el)
                    },
                    checked: el == payment,
                    name: `${el.name}`
                })}
            />
        </div>
    )
}

export default PaymentMethod