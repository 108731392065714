import React, { useRef, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import { connect } from 'react-redux'
import { Layout, Head, Stepper, Mandala, Breadcrumbs, Auth } from '@common'
import { NewsletterSection, AttentionSection} from '@sections'
import { WPApi} from '../api'
import { customerActions, cartActions } from '@stores'

import {
	CustomerForm,
	ProductsSummary,
	Delivery,
	PaymentMethod,
	DiscountAndContinue,
	ErrorMessage,
	OrderSummary,
	createOrder,
	initPayment,
} from '@pages/Checkout'

import './styles/checkout.scss'

const Checkout = (props) => {
	const stepper = useRef({ setIndex: () => {} })
	const {
		customer,
		changeValue,
		cart,
		resetCart,
		setShipping,
		setPayment,
		onError,
		setCoupon,
		setOrder,
		changeProductQty,
		removeFromCart,
		pageContext,
	} = props
	const { page } = pageContext
	const { orderInApi, imBusy } = cart
	let isLoggedIn = 'false'

	if (typeof window !== 'undefined') {
		isLoggedIn = localStorage.getItem('isLoggedIn');
	}
	// 
	// localStorage.getItem('isLoggedIn')

	if (cart.items.length === 0) {
		return (
			<Layout seo={pageContext.page.seo}
			siteMetaData={pageContext.page.siteMetaData}>
			<AttentionSection height={350}/>
				<div className='checkout'>
						<div className='container'>
							<Breadcrumbs
								elements={[
									{
										label: 'Terapie',
										url: '/categories/',
									},
									{
										label: page.title,
										url: `/${page.slug}/`,
									},
								]}
							/>
							<h1>Brak rzeczy w koszyku</h1>
						</div>
					</div>
					<Mandala height={250}>
						<div className='container magictime swap'>
							<div className='row justify-content-center'>
								<Link className='button' to={`/categories/`}>
									Wróć do sklepu
								</Link>
							</div>
						</div>
					</Mandala>
					<NewsletterSection />
			</Layout>
		)
	}
	if (isLoggedIn !== 'true'){
		return (
		<Layout seo={pageContext.page.seo}
			siteMetaData={pageContext.page.siteMetaData}>
			<AttentionSection height={350}/>
				<div className='checkout'>
						<div className='container'>
							<Breadcrumbs
								elements={[
									{
										label: 'Terapie',
										url: '/categories/',
									},
									{
										label: page.title,
										url: `/${page.slug}/`,
									},
								]}
							/>
							<h1>Aby kontynuować zakupy proszę zalogować się na swoje konto.</h1>
							<div className="row justify-content-center">
							<Auth outside={true}/>
							</div>
						</div>
					</div>
					<Mandala height={250}>
						<div className='container magictime swap'>
							<div className='row justify-content-center'>
								<Link className='button' to={`/categories/`}>
									Wróć do sklepu
								</Link>
							</div>
						</div>
					</Mandala>
					<NewsletterSection />
			</Layout>
		)
	}
	if (orderInApi.id & isLoggedIn === 'true') {
		return (
			<Layout seo={pageContext.page.seo}
			siteMetaData={pageContext.page.siteMetaData}>
			<AttentionSection height={350}
					title={'Zamówienie #' + orderInApi.id}
			/>

				<div className='checkout'>
						<div className='container'>
              <div className='col-sm-12'>
                <ErrorMessage cart={cart} />
				<OrderSummary cart={cart}
				resetCart={resetCart}
                // onInitPayment={()=>{
                //   initPayment(props).then((payment)=>{
                //     window.location.href = payment.redirectUri
                //   }).catch(({ data }) => {
                //     onError(data)
                //   })
                // }}
                />
							</div>
						</div>
				</div>
			</Layout>
		)
	}

	return (
		<Layout seo={pageContext.page.seo}
		siteMetaData={pageContext.page.siteMetaData}>
		<AttentionSection height={350}
			title={'Podsumowanie zamówienia'}
		/>

			<div className='checkout'>
					<div className='row justify-content-center'>
						<div className='col-md-8 col-12'>
							<div className='container'>
								<Stepper ref={stepper} withScroll={true}>
									<div
										className='col-12'
										title={'1. Podsumowanie'}
									>
										<ProductsSummary
											cart={cart}
											changeProductQty={changeProductQty}
											removeFromCart={removeFromCart}
										/>
										<ErrorMessage cart={cart} />
										<DiscountAndContinue
											customer={customer.customer}
											sum={cart.sum}
											shipping={cart.shipping}
											nextStep={() => {
												stepper.current.setIndex(1)
											}}
											coupon={cart.coupon}
											onCouponChange={setCoupon}
										/>
									</div>
									<div
										className='col-12'
										title={'2. Wypełnij dane'}
									>
										<CustomerForm
											store={customer}
											changeValue={changeValue}
											cart={cart}
										/>
										<Delivery
											cart={cart}
											setShipping={setShipping}
										/>
										<PaymentMethod 
											customer={customer}
											setPayment={setPayment}/>
										<ErrorMessage cart={cart} />
										<DiscountAndContinue
											sum={cart.sum}
											customer={customer.customer}
											shipping={cart.shipping}
											nextStep={() => {
												createOrder(props)
													.then((data) => {
														setOrder(data)
													})
													.catch(({ data }) => {
														onError(data)
													})
												if(props.customer.payment.name === 'Przelew'){
													WPApi.sendDirectPaymentNotification(props.customer.customer, props.cart.sum.val)
												} else if (props.customer.payment.name === 'Płatne przy odbiorze'){
													WPApi.sendPersonalCollectionNotification(props.customer.customer, props.cart.sum.val)
												} else if (props.customer.payment.name === 'Blik'){
													WPApi.sendBlikNotification(props.customer.customer, props.cart.sum.val)
												}
											}}
											label={'Potwierdź zamówienie'}
											coupon={cart.coupon}
											onCouponChange={setCoupon}
										/>
									</div>
								</Stepper>
							</div>
						</div>
					</div>
					<div className="row justify-content-center">
							<Auth outside={true}/>
							</div>
			</div>
		</Layout>
	)
}

const mapStateToProps = (state) => {
	return { customer: state.Customer, cart: state.Cart }
}

const mapActionToDispatch = { ...customerActions, ...cartActions }

export default connect(mapStateToProps, mapActionToDispatch)(Checkout)
