import React, { useRef, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import { ResizedImage as Image } from '@common'


const ProductSummaryMobileItem = ({ image, name, quantity, value }) => {
	return (
		<div className='summary-item'>
			<div className='col-6 justify-content-center'>
				<Image {...image} size={'150w'} />
			</div>
			<div className='col-6'>
				<div className='summary-item-description'>
					<h5>
						{quantity}x {name}
					</h5>
				</div>
				<div className='justify-content-center align-items-center summary-item-controls'>
					<h5>{value} PLN</h5>
				</div>
			</div>
		</div>
	)
}

const ProductSummaryDesktopItem = ({ image, name, value, quantity }) => {
	return (
		<div className='summary-item'>
			<Image {...image} size={'150w'} />
			<div className='col'>
				<h5 className='text-center'>
					{quantity}x {name}
				</h5>
			</div>
			<div className='col no-pm'>
				<h5 className='text-right'>{value} PLN </h5>
			</div>
		</div>
	)
}

const OrderSummary = ({ cart, onInitPayment, resetCart }) => {
	const { items, orderInApi } = cart
	const {
		coupon_lines,
		discount_total,
		total,
		shipping_lines,
		currency,
	} = orderInApi

	return (
		<div className='summary'>
				<div className='summary-stats'>
					<h5>
						<strong>Twoje zamówienie zostało przyjęte do realizacji. Sprawdź swój adres email.</strong>
					</h5>
			</div>
			<div className='summary-stats'>
				<h5>
					ID zamówienia: <strong>{orderInApi.id}</strong>
				</h5>
			</div>
			<div className='d-none d-md-block'>
				{items.map((el, k) => (
					<ProductSummaryDesktopItem {...el} key={k} />
				))}
			</div>
			{/* mobile */}
			<div className='d-md-none'>
				{items.map((el, k) => (
					<ProductSummaryMobileItem {...el} key={k} />
				))}
			</div>

			<div className='summary-stats'>
				{coupon_lines.length !== 0 && (
					<div className='row'>
						<div className='col-12 col-md-6'>
							<h5>
								Użyty kupon:{' '}
								<strong>{coupon_lines[0].code}</strong>
							</h5>
						</div>
						<div className='col-12 col-md-6'>
							<h5 className='text-right'>
								Wysokość rabatu:{' '}
								<strong>
									{discount_total}
									{currency}
								</strong>
							</h5>
						</div>
					</div>
				)}
				<div className='row'>
					
					<div className='col-12 col-md-6'>
						<h5>
							Koszt przesyłki:{' '}
							<strong>
							{shipping_lines[0].method_title}{' - '}
								{shipping_lines[0].total}
								{currency}
							</strong>
						</h5>
					</div>
					<div className='col-12 col-md-6'>
						<h5 className='text-right'>
							Do zapłaty:{' '}
							<strong>
								{total}
								{currency}
							</strong>
						</h5>
					</div>
				</div>

				<div className='row justify-content-center'>
					<div className='col text-center'>
					<Link
						onClick={() => resetCart()}
						className="app-button"
							to='/'
						>
							Zakończ zakupy
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default OrderSummary
