import React from 'react'
import {Auth} from '@common'
 
const DiscountAndContinue = ({ sum, shipping, nextStep, coupon, onCouponChange, label="Dalej", customer }) => {
	let disabled = false
	if(customer.first_name == '' || customer.last_name == '' || customer.email == '' || customer.phone == ''){
		disabled = true
	}
	return (
		<div>
		<div className='summary-bottom'>
			<div className='discount'>
				<input
					className='border-input'
					type='text'
					placeholder='Mam kupon rabatowy'
					onChange={(ev) => {
						onCouponChange(ev.target.value)
					}}
					value={coupon}
				/>
			</div>
			
			<div className='sum'>
				<h5>Razem:{' '}<span className='price'>
                {typeof shipping.total === 'number' ? (
                    sum.val + shipping.total
                ) : (
                    sum.val
                )}
                {' '}PLN
            </span></h5>
				<button disabled={disabled} onClick={nextStep} className='button'>{label}</button>
			</div>
		</div>
		{disabled ? (
			<div>
					<div className='row justify-content-center'>
						<h5>Błędne dane kontaktowe. Proszę zaktualizować dane w panelu użytkownika.</h5>
					</div>
					
			</div>
					
				):(null)}
		</div>
	)
}

export default DiscountAndContinue
