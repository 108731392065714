import React from 'react'
import { ResizedImage as Image, QuantityChanger } from '@common'

const ProductSummaryMobileItem = ({
	product_id,
	quantity,
	image,
	name,
	value,
	removeFromCart,
	changeProductQty,
	
}) => {
	return (
		<div className='summary-item'>
			<div className='col-6'>
				<Image {...image} size={'150w'} />
			</div>
			<div className='col-6'>
				<div className='summary-item-description'>
					<a
						className='summary-item-remove'
						onClick={() => {
							removeFromCart({
								product_id,
							})
						}}
					>
						<i className='icofont-close-line icofont-2x' />
					</a>
					<h5>{name}</h5>
					<div className='justify-content-center align-items-center summary-item-controls'>
						<a
							className='summary-control'
							onClick={() => {
								changeProductQty({
									product_id,
									quantity: quantity - 1,
								})
							}}
						>
							<i className='icofont-minus' />
						</a>
						<input
							className='summary-input'
							value={quantity}
							onChange={(ev) => {
								changeProductQty({
									product_id,
									quantity: ev.target.value,
								})
							}}
							type='text'
							min='1'
							max='999'
							step='1'
						/>
						<a
							className='summary-control'
							onClick={() => {
								changeProductQty({
									product_id,
									quantity: quantity + 1,
								})
							}}
						>
							<i className='icofont-plus' />
						</a>
					</div>
				</div>
				<div className='justify-content-center align-items-center summary-item-controls'>
					<h5>{value} PLN </h5>
				</div>
			</div>
		</div>
	)
}

const ProductSummaryDesktopItem = ({
	product_id,
	quantity,
	image,
	name,
	value,
	removeFromCart,
	changeProductQty,
}) => {
	return (
		<div className='summary-item'>
            <Image {...image} />
			<div className='col-6'>
				<h5>{name}</h5>
			</div>
			<div className='col'>
				<QuantityChanger onChange={(qty)=>{
					changeProductQty({
						product_id,
						quantity: qty,
					})
				}} quantity={quantity}/>
			</div>
			<div className='col'>
				<h5>{value} PLN </h5>
			</div>
			<div className='col no-pm text-right '>
                <button
                    className='summary-item-remove'
					onClick={() => { removeFromCart({ product_id }) }}
				>
                    <i className='icofont-close-line icofont-2x' />
                </button>
			</div>
		</div>
	)
}

const ProductsSummary = ({
	cart,
	changeProductQty,
	removeFromCart,
	presentation = false,
}) => {
	const { items, shipping, sum, coupon } = cart
	return (
		<div className='summary'>
			{/* desktop */}
			<div className='d-none d-md-block'>
				{items.map((el, k) => (
					<ProductSummaryDesktopItem
						{...el}
						key={k}
						changeProductQty={changeProductQty}
						removeFromCart={removeFromCart}
					/>
				))}
			</div>
			{/* mobile */}
			<div className='d-md-none'>
				{items.map((el, k) => (
					<ProductSummaryMobileItem
						{...el}
						key={k}
						changeProductQty={changeProductQty}
						removeFromCart={removeFromCart}
					/>
				))}
			</div>
			
		</div>
	)
}

export default ProductsSummary
